import { reactive } from 'vue';
//import { auth } from '@/firebase';
import { createRouter, createWebHistory } from 'vue-router';
//import { onAuthStateChanged } from 'firebase/auth';
//import { db } from '@/firebase';
//import { getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';
console.log('we in the router');

export let userInfo = reactive({}); 


  const routes = [
    {
      path: '/',
      name: 'HomePage',
      component: () => import('@/views/HomePage.vue'), // Lazy-load
    },
    {
      path: '/reading',
      name: 'ReadingStart',
      component: () => import('@/views/ReadingStart.vue'), // Lazy-load
    },
  ];
  

const router = createRouter({
  history: createWebHistory(),
  routes,
});
/*
let isAuthInitialized = false; // Flag to indicate whether auth state has been checked
const authPromise = new Promise((resolve) => {
  onAuthStateChanged(auth, async (user) => {
    isAuthInitialized = true;
    console.log('asdfffa');

    if (user) {
      console.log('asdfffb');

      userInfo = await checkOrCreateUserDocument(user); // Await the Promise
      console.log('User info object:', userInfo);
      console.log('asdfffc');

    } else {
      userInfo = {};
      console.log('asdfffd');

    }
    console.log('asdfffe');

    resolve(user);
  });
});

router.beforeEach(async (to, from, next) => {
  console.log('aaaaa');

  if (!isAuthInitialized) {
    await authPromise; // Wait for auth state to be initialized
  }
  console.log('bbb');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
 

  const isAuthenticated = auth.currentUser;
console.log('user',userInfo);
  if (requiresAuth && !isAuthenticated) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }, // Store the intended path for after login
    });
  } else{


    next();
  }
});



// Function to get user information from Firestore
async function getUserInfo(userRef) {
  const docSnap = await getDoc(userRef);
  console.log('asdfff3');

  if (docSnap.exists()) {
    console.log('asdfff4');

    console.log("User document already exists");
    // Return the user object with additional information
    return { userId: docSnap.id, ...docSnap.data() };
  }
  console.log('asdfff5');

  return null; // Return null if the document does not exist
}

// Function to create a new user document in Firestore
async function createUserDocument(userRef, user) {
  await setDoc(userRef, {
    email: user.email,
    createdAt: serverTimestamp(),
    // Add any other default fields you need
  });
  console.log("New user document created.");
  // Return a basic user object
  return { userId: user.uid };
}





// Main function to check or create the user document and return user info
async function checkOrCreateUserDocument(user) {
  if (!user) return null; // Ensure the user object is valid

  const userRef = doc(db, 'users', user.uid);
  // Attempt to get user info from Firestore
  userInfo = await getUserInfo(userRef);
  console.log('asdfff1a'); 
  if (userInfo) {
    // User exists, return the extended user object
    console.log('asdfff2'); 
    return userInfo;
  } else {
    console.log('asdfffz'); 
    // User doesn't exist, create a new document and return a basic user object
    return await createUserDocument(userRef, user);
  }
}

*/

export default router;